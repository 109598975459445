<script>
import appConfig from '@src/app.config'

export default {
  page: {
    title: 'Home',
    meta: [{ name: 'description', content: appConfig.description }],
  },
}
</script>

<template>
<v-container fluid class="home-background pa-0 overflow-x-hidden fill-height">
  <v-row class="overlay">
    <v-col cols="12" class="pa-10 align-self-center">
      <v-btn text color="red darken-2" absolute right top link to="/login">Login <v-icon>mdi-account-lock</v-icon></v-btn>
        <h1>ECMO</h1>
        <h2>Due diligence, made easy.</h2>
    </v-col>
  </v-row>
</v-container>
</template>
<style>
.home-background{
  background: url('../../assets/home_bg_2560.jpg') no-repeat;
  background-size: cover;
}

.overlay{
  height:100%;
  width:100%;
  background: rgb(255,255,255);
  background: linear-gradient(45deg, rgba(255,255,255,.9) 0%, rgba(255,255,255,.9) 53%, rgba(255,255,255,0) 100%);
}

.intro{
  width:50%;
}

@media screen and (max-width: 690px){
  .intro{
    width: 90%;
    margin: 25px auto;
  }
}

</style>